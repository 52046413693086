<script>
import pdf from 'vue-pdf'
import toggleElementClass from '@utils/toggleElementClass'

export default {
  components: {
    pdf,
  },
  props: {
    url: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
      default: () => null,
    },
    onCloseModal: {
      type: Function,
      default: () => '',
    },
  },
  data() {
    return {
      isIE: !!window.navigator.msSaveOrOpenBlob,
      pageCount: [],
    }
  },
  mounted() {
    toggleElementClass('addClass', 'fixed', document.body)
  },
  destroyed() {
    toggleElementClass('removeClass', 'fixed', document.body)
  },
}
</script>

<template>
  <v-overlay :value="url" color="#222F44" opacity="0.8">
    <v-dialog v-model="url" hide-overlay content-class="pdfModal" persistent>
      <div :class="$style.modal">
        <div :class="$style.modalHeader">
          <v-icon @click="onCloseModal">mdi-expenseDetailsCustomClose</v-icon>
        </div>

        <div :class="$style.modalInner">
          <pdf
            style="display: none"
            :src="url"
            @num-pages="pageCount = new Array($event)"
          />
          <!-- Actual document -->
          <pdf
            v-for="(page, index) in pageCount"
            :key="index"
            :src="url"
            :page="index + 1"
            style="display: block; width: 100%"
          />
        </div>
      </div>
    </v-dialog>
  </v-overlay>
</template>

<style lang="scss" module>
@import '@design';

.modal {
  display: flex;
  min-height: 100%;
  text-align: center;
}

.modalHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
  padding-right: 15px;
  line-height: 38px;
  background: $light-grey;
  box-shadow: 0 -20px 20px 4px #000;
}

.modalInner {
  width: 100%;
  padding-top: 60px;
  overflow-y: auto;
  background: white;
}
</style>
